<template>
  <common :title="title">
    <!-- 状态按钮 -->
    <template #operate-btn>
      <div class="operate-r">
        <div v-if="detailData.activityStatus == 2" class="operate-rl">
          <div
            v-if="
              detailData.limitCount > detailData.applyUserCount ||
              detailData.limitCount == 0
            "
            class="item"
          >
            <div
              class="oItem"
              v-if="
                detailData.isApplied != 1 ||
                (detailData.isApplied == 1 && detailData.allowApplyMulti == 1)
              "
              @click="toBeginApply"
            >
              <div class="txt">活动报名</div>
            </div>
            <div class="oItem" v-else>
              <div class="txt">已报名</div>
            </div>
          </div>
          <div v-else class="item activityDetail-red">
            <div class="txt">报名人数已满</div>
          </div>
        </div>
        <div v-else class="operate-rl">
          <div class="item activityDetail-gray">
            <div class="txt">暂不可报名</div>
          </div>
        </div>
      </div>
    </template>
    <div class="detailData">
      <div class="item-l">
        <div class="active-Picture">
          <img :src="detailData.activityPicture" alt="" />
        </div>
        <div class="rules">活动规则</div>
        <p>
          <span>报名开始时间：</span>
          <span> {{ detailData.applyStartDate }}</span>
        </p>
        <p>
          <span>活动开始时间：</span>
          <span> {{ detailData.startDate }}</span>
        </p>
        <p>
          <span>活动人数：</span>
          <span class="number">
            <span>{{ detailData.applyUserCount }}</span
            >/{{
              detailData.limitCount == "0" ? "不限制" : detailData.limitCount
            }}</span
          >
        </p>
        <p>
          <span>活动地点：</span>
          <span> {{ detailData.activityAddress }}</span>
        </p>
      </div>
      <div class="item-r">
        <div class="activityDetail-c" v-if="detailData.hasFeedback == 1">
          <div class="title">活动回顾</div>
          <div class="body">
            <v-h5Mtml :content="detailData.feedback"></v-h5Mtml>
          </div>
          <div class="fbVideoUrl" v-if="detailData.fbVideoUrl">
            <video :src="detailData.fbVideoUrl" controls="controls"></video>
          </div>
          <template v-if="detailData.fbImageUrls">
            <div
              class="fbImageUrls"
              v-for="(item, index) in detailData.fbImageUrls
                ? detailData.fbImageUrls.split(',')
                : []"
              :key="index"
            >
              <img
                :src="item"
                mode="widthFix"
                @click="bannerClick(detailData.fbImageUrls.split(','), index)"
              />
            </div>
          </template>
        </div>
        <div class="activityDetail-c" v-else>
          <div class="title">活动内容</div>
          <div class="body">
            <v-h5Mtml :content="detailData.content"></v-h5Mtml>
          </div>
          <div class="fbVideoUrl" v-if="detailData.videoUrl">
            <video
              id="video"
              :src="detailData.videoUrl"
              controls="controls"
            ></video>
          </div>
          <template
            v-if="detailData.imageUrls && detailData.imageUrls != undefined"
          >
            <div
              class="fbImageUrls"
              v-for="(item, index) in detailData.imageUrls
                ? detailData.imageUrls.split(',')
                : []"
              :key="index"
            >
              <img :src="item" />
            </div>
          </template>
        </div>
      </div>
    </div>
    <!-- 短期模式场次 -->
    <custom-dialog
      title="活动场次"
      v-model="isShowShortPeriodDialog"
      @confirm="confirmSureSelect"
    >
      <div class="shortPeriod">
        <div
          :class="['scheduleList', showClass(item)]"
          v-for="(item, index) in detailData.scheduleList"
          :key="index"
          @click="toSelect(item)"
        >
          <div class="content">
            <div class="label">报名时间：</div>
            <div class="value">
              {{ item.applyStartDate }}-{{ item.applyEndDate }}
            </div>
          </div>
          <div class="content">
            <div class="label">活动时间：</div>
            <div class="value">{{ item.startDate }}-{{ item.endDate }}</div>
          </div>
          <div class="content">
            <div class="label">参与人数：</div>
            <div class="value">
              <span> {{ item.applyUserCount }}</span
              >/{{ item.limitCount == 0 ? "不限制" : item.limitCount }}
            </div>
          </div>
          <!-- <div class="content">
            <div class="label">单次限报：</div>
            <div class="value">
              {{
                item.perLimitCount && item.perLimitCount != 0
                  ? item.perLimitCount + "人"
                  : "不限制"
              }}
            </div>
          </div> -->
          <div class="content" v-if="item.remarks">
            <div class="label">备注：</div>
            <div class="value">
              {{ item.remarks ? item.remarks : "" }}
            </div>
          </div>
          <div class="content" v-if="item.activityStatus == 2">
            <div class="label">
              <template>
                <template
                  v-if="
                    item.limitCount > item.applyUserCount ||
                    item.limitCount == 0
                  "
                >
                  <span v-if="item.isApplied == 1"> 已报名 </span>
                  <span v-else> 可选择 </span>
                </template>
                <template v-else>
                  <span>该场次报名人数已满</span>
                </template>
              </template>
            </div>
            <div class="value">
              {{ item.remarks ? item.remarks : "" }}
            </div>
          </div>
          <div class="content" v-else>
            <div class="label">暂不可报名</div>
          </div>
          <div class="select-img">
            <img
              :src="
                item.isSelect
                  ? require('./img/selected.png')
                  : require('./img/not-select.png')
              "
              alt=""
            />
          </div>
        </div>
      </div>
    </custom-dialog>
    <!-- 周期弹窗 -->
    <custom-dialog
      v-model="isShowSeasonalDialog"
      @confirm="confirmSeasonal"
      confirmText="确认"
    >
      <div class="seasonal-content">
        <div class="text">
          {{ detailData.startDate }}到{{ detailData.endDate }}
        </div>
        <div
          class="text"
          v-for="(item, index) in detailData.scheduleList"
          :key="index"
        >
          每{{ weekList[item.week]
          }}{{ item.dayTimeBucket }}的周期活动是否确认报名
        </div>
      </div>
    </custom-dialog>
    <!-- 志愿者弹窗 -->
    <custom-dialog
      title="志愿者申请"
      v-model="isShowPeriodDialog"
      cancelText="取消"
      @confirm="confirmApply"
    >
      <div class="section">
        <div class="content">
          <div class="label">姓名：</div>
          <div class="value">
            <v-input
              v-model="form.name"
              :maxlength="10"
              placeholder="请输入姓名"
            ></v-input>
          </div>
        </div>
        <div class="content">
          <div class="label">手机号：</div>
          <div class="value">
            <v-input
              type="number"
              placeholder="请输入手机号"
              :maxlength="11"
              v-model="form.phone"
            ></v-input>
          </div>
        </div>
        <div class="content">
          <div class="label">申请理由：</div>
          <div class="value">
            <v-input
              v-model="form.pursueReason"
              placeholder="请输入申请理由"
              type="textarea"
            ></v-input>
          </div>
        </div>
        <div class="content">
          <div class="label">签字：</div>
          <div class="value sign">
            <signCanvas
              ref="esign"
              :width="330"
              :height="242"
              :isCrop="isCrop"
              :lineWidth="lineWidth"
              :lineColor="lineColor"
              :bgColor.sync="bgColor"
            />
          </div>
        </div>
      </div>
    </custom-dialog>
    <!-- 时间银行 -->
    <custom-dialog
      title="活动场次"
      v-model="isShowBank"
      cancelText="取消"
      :isShowFooter="false"
      @confirm="confirmApply"
    >
      <div class="calendar">
        <calendar
          ref="calendar"
          :haveSessionsList="haveSessionsList"
          @dateClick="dateClick"
          :canChangeMonth="true"
          @changeMonth="changeMonth"
        ></calendar>
      </div>
      <div class="timeSlotList">
        <div
          :class="['timeSlotItem', { 'timeSlotItem-select': item.isSelect }]"
          v-for="(item, index) in timeSlotList"
          :key="index"
          @click="timeSlotSelect(item)"
        >
          <div
            v-if="!item.isSelect"
            :class="['checkBox', { disabled: item.canApply == 0 }]"
          ></div>
          <div v-else class="checkBox select"></div>
          <div :class="['timeSlot', { isFull: item.canApply == 0 }]">
            {{ item.dayTimeBucket }}
          </div>
          <div :class="['joinNum', { isFull: item.canApply == 0 }]">
            可报名人数<span>{{ item.applyUserCount }}</span
            >/{{ item.limitCount == 0 ? "不限制" : item.limitCount }}
          </div>
        </div>
      </div>
      <div class="footer-btn">
        <div class="btn" @click="confirmSureSelect">确定</div>
      </div>
    </custom-dialog>
  </common>
</template>

<script>
import {
  activityDetail,
  ifVolunteerAndCommitmentLetter,
  myActivityApplicantsList,
  applyActivityUrl,
  volunteerApplication,
  getScheduleListUrl,
} from "./api";
import calendar from "../components/calendar.vue";
import common from "../components/common.vue";
import customDialog from "../components/customDialog.vue";
import signCanvas from "@/components/bussiness/signCanvas";
import { base64toFile } from "@/utils/utils.js";
import { mapState } from "vuex";
export default {
  name: "detailData",
  components: {
    common,
    customDialog,
    signCanvas,
    calendar,
  },
  props: {},
  data() {
    return {
      haveSessionsList: [], //是否有场次的天数
      timeSlotList: [],
      isShowBank: false,
      weekList: ["", "周一", "周二", "周三", "周四", "周五", "周六", "周日"],
      form: {
        name: "", // 志愿者姓名
        phone: "", // 志愿者电话
        pursueReason: "", // 申请原因
      },
      isCrop: false,
      lineWidth: 6,
      lineColor: "#979797",
      bgColor: "#D8D8D8",
      isShowSeasonalDialog: false, //周期场次弹窗
      isShowShortPeriodDialog: false, //短期场次弹窗
      isShowPeriodDialog: false, //志愿者弹窗
      title: "",
      detailData: {},
      uploadUrl: "/gateway/blade-resource/oss/endpoint/put-file",
      searchParams: {
        activityId: "",
      },
      scheduleId: [], //场次id
      applicantsIdList: [], //活动参与人
    };
  },
  created() {},
  mounted() {
    const { activityId, activityName } = this.$route.query;
    if (![null, undefined, ""].includes(activityId)) {
      this.title = activityName;
      this.searchParams.activityId = activityId;
      this.getActivityDetail(activityId);
    }
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  methods: {
    confirmSureBank() {},
    timeSlotSelect(item) {
      if (item.canApply == 0) {
        return;
      }
      if (item.isSelect && this.detailData.allowApplyMulti == 1) {
        item.isSelect = !item.isSelect;
        this.$forceUpdate();
        return;
      }
      if (this.detailData.allowApplyMulti == 1) {
        item.isSelect = true;
      } else {
        this.detailData.scheduleList.forEach((ele) => {
          ele.isSelect = false;
        });
        item.isSelect = true;
      }
      this.$forceUpdate();
    },
    changeMonth(firstDay) {
      // console.log(firstDay);
    },
    // 选择某一日期
    dateClick(item) {
      this.timeSlotList = [];
      const list = this.detailData.scheduleList;
      list.forEach((ele) => {
        ele.isSelect = false;
        if (ele.day == item.date) {
          this.timeSlotList.push(ele);
        }
      });
      console.log(item, this.timeSlotList, "item----");
    },
    // 获取当前时间
    getNow() {
      const time =
        this.haveSessionsList.length > 0 ? this.haveSessionsList[0] : "";
      const timeFormat = time.replace(/-/g, "/");
      this.$nextTick(() => {
        this.$refs.calendar.getNow(timeFormat);
      });
    },
    // 获取周期场次
    async getSessionList() {
      let res = await this.$axios(getScheduleListUrl, {
        params: {
          activityId: this.detailData.activityId,
        },
      });
      if (res.code == 200) {
        this.scheduleId = [];
        res.data.forEach((ele) => {
          this.scheduleId.push(ele.id);
        });
      }
    },
    //周期确认
    async confirmSeasonal() {
      await this.getSessionList();
      await this.getPeopleList();
      await this.sureApply();
      this.isShowSeasonalDialog = false;
      //   let params = {
      //     activityId: this.detailData.activityId,
      //     applicantsIdList: this.applicantsIdList,
      //     houseId: this.houseId || "P1C2A2S1D1V7B2U2R302",
      //     userId: this.userId || "1763412296209608705",
      //     scheduleId: this.scheduleId.join(","),
      //   };
      //  await this.$axios.post(applyActivityUrl,params)

      //   if (res.code == 200) {

      //   }
    },
    //确认申请志愿者
    confirmApply() {
      if (!this.form.name) {
        this.$toast("请填写姓名");
        return;
      }
      if (!this.form.phone) {
        this.$toast("请填写手机号");
        return;
      }
      if (this.form.phone.length != 11) {
        this.$toast("手机号格式错误");
        return;
      }
      if (!this.form.pursueReason) {
        this.$toast("请填写申请理由");
        return;
      }
      this.$refs.esign
        .generate()
        .then(async (res) => {
          this.resultImg = res;
          console.log(base64toFile(res), "009");
          await this.uploadImg(base64toFile(res));
        })
        .catch((err) => {
          alert(err); // 画布没有签字时会执行这里 'Not Signned'
        });
    },
    // 签名转为图片
    async uploadImg(file) {
      var formData = new FormData();
      formData.append("file", file);
      let res = await this.$axios.post(this.uploadUrl, formData);
      if (res.code == 200) {
        this.form.signatureImage = res.data.link;
        this.postSignData();
      }
    },
    // 签署承诺书接口
    async postSignData() {
      let params = {
        userId: this.userId || "1763412296209608705",
        tenantId: this.tenantId || "P1C2A2S1D1",
        ...this.form,
      };
      let res = await this.$axios.post(volunteerApplication, params);
      if (res.code === 200) {
        this.isShowPeriodDialog = false;
        this.$toast({ message: "申请成功", duration: 2000 });
      }
    },
    // 确认提交
    sureApply() {
      // if (this.applicantsIdList.length === 0) {
      //   this.$toast({ message: "请选择需要报名参与人", duration: 300 });
      //   return;
      // }
      // console.log(this.applicantsIdList, "this.applicantsIdList---");
      let params = {
        activityId: this.detailData.activityId,
        applicantsIdList: this.applicantsIdList,
        houseId: this.houseId,
        userId: this.userId,
        scheduleId: this.scheduleId.join(","),
      };
      this.$axios.post(applyActivityUrl, params).then((res) => {
        if (res.code === 200) {
          this.$toast({ message: "报名成功", duration: 300 });
          this.$router.replace({
            name: "registrationSuccess",
          });
        } else {
          if (res.msg) {
            this.$toast({ message: res.msg, duration: 300 });
          }
        }
      });
    },
    //确认选择场次
    async confirmSureSelect() {
      if (this.detailData.activityCategory == 20) {
        let arr = [];
        this.timeSlotList.forEach((ele, ind) => {
          arr.push(ele.isSelect);
        });
        if (arr.indexOf(true) == -1) {
          this.$toast("请选择场次");
          return;
        }
        this.scheduleId = [];
        this.timeSlotList.forEach((ele, ind) => {
          if (ele.isSelect) {
            this.scheduleId.push(ele.id);
          }
        });
      } else {
        let arr = [];
        this.detailData.scheduleList.forEach((ele, ind) => {
          arr.push(ele.isSelect);
        });
        if (arr.indexOf(true) == -1) {
          this.$toast({ message: "请选择场次", duration: 2000 });
          return;
        }
        this.scheduleId = [];
        this.detailData.scheduleList.forEach((ele, ind) => {
          if (ele.isSelect) {
            this.scheduleId.push(ele.id);
          }
        });
      }
      //获取参与人
      await this.getPeopleList();
      //确认报名
      await this.sureApply();
    },
    // 获取参与人列表
    async getPeopleList() {
      let params = {
        activityId: this.detailData.activityId,
        scheduleId: this.scheduleId.join(","),
        userId: this.userId,
        addSelf: 1,
      };
      const res = await this.$axios.get(`${myActivityApplicantsList}`, {
        params,
      });
      if (res.code === 200) {
        if (this.detailData.activityCategory == 20) {
          this.applicantsIdList = res.data.records
            ? res.data.records.map((v) => v.applicantsId)
            : [];
        } else {
          this.applicantsIdList = res.data.records
            ? res.data.records
                .filter((v) => v.applicantsRelation == 0)
                .map((v) => v.applicantsId)
            : [];
        }
      } else {
        if (res.msg) {
          this.$toast({ message: res.msg, duration: 300 });
        }
      }
    },
    //选择场次
    toSelect(item) {
      const result = this.verifyFiled(item);
      if (!result) return;
      if (item.isSelect && this.detailData.allowApplyMulti == 1) {
        item.isSelect = !item.isSelect;
        this.$forceUpdate();
        return;
      }
      if (this.detailData.allowApplyMulti == 1) {
        item.isSelect = true;
      } else {
        this.detailData.scheduleList.forEach((ele) => {
          ele.isSelect = false;
        });
        item.isSelect = true;
      }
      this.$forceUpdate();
    },
    //校验
    verifyFiled(item) {
      let result = true;
      if (item.activityStatus == 2) {
        if (item.limitCount > item.applyUserCount || item.limitCount == 0) {
          if (item.isApplied != 1) {
            result = true;
          } else {
            result = false;
            this.$toast("该场次已报名不可再报名");
            return;
          }
        } else {
          result = false;
          this.$toast("该场次报名人数已满不可报名");
          return;
        }
      } else {
        result = false;
        this.$toast("该场次暂不可报名");
        return;
      }
      return result;
    },
    showClass(item) {
      if (item.activityStatus == 2) {
        if (item.limitCount > item.applyUserCount || item.limitCount == 0) {
          if (item.isApplied != 1) {
            return "active";
          } else {
            return "inactive";
          }
        } else {
          return "inactive";
        }
      } else {
        return "inactive";
      }
    },
    //时间银行查询是否为志愿者
    async ifVolunteerAndCommitmentLetter() {
      return await this.$axios.post(ifVolunteerAndCommitmentLetter, null, {
        params: {
          userId: this.userId,
        },
      });
    },
    //去报名
    async toBeginApply() {
      //时间银行
      if (this.detailData.activityCategory == 20) {
        let res = await this.ifVolunteerAndCommitmentLetter();
        if (res.code == 200) {
          if (res.data.isVolunteer == 0) {
            // 如果不是志愿者
            this.isShowPeriodDialog = true;
            return;
          } else {
            this.isShowBank = true;
            this.getNow();
          }
        } else {
          return;
        }
      }
      // 是否有场次
      if (
        this.detailData.scheduleList &&
        this.detailData.scheduleList.length != 0
      ) {
        //短期模式
        this.isShowShortPeriodDialog = true;
      } else {
        this.toApply();
      }
    },
    //获取活动列表
    getActivityDetail() {
      this.$axios
        .get(activityDetail, {
          params: {
            ...this.searchParams,
            userId: this.userId,
          },
        })
        .then((res) => {
          if (res.code == 200) {
            this.detailData = res.data;
            //加isSelect字段
            this.detailData.scheduleList &&
              this.detailData.scheduleList.forEach((ele) => {
                ele.isSelect = false;
                this.haveSessionsList.push(ele.day);
              });
          }
        });
    },
  },
};
</script>

<style scoped lang="less">
.footer-btn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 40px;
  box-sizing: border-box;
  .btn {
    margin-top: 20px;
    width: 239px;
    height: 50px;
    box-sizing: border-box;
    background: #1874fd;
    border-radius: 6px;
    // padding: 13px 102px;
    font-weight: 600;
    font-size: 18px;
    color: #ffffff;
    line-height: 50px;
    text-align: center;
    font-style: normal;
  }
}
.calendar {
  margin-top: 20px;
  box-sizing: border-box;
}
.timeSlotList {
  padding: 0 12px;
  max-height: 100px;
  overflow-y: auto;
  box-sizing: border-box;
  .timeSlotItem-disabled {
    color: rgba(0, 0, 0, 0.25) !important;
  }
  .timeSlotItem-select {
    // background: #f6f9f8 !important;
  }
  .timeSlotItem {
    // height: 108px;
    display: flex;
    align-items: center;
    padding: 10px 30px;
    box-sizing: border-box;
    .checkBox {
      width: 16px;
      height: 16px;
      border: 2px solid #1874fd;
      box-sizing: border-box;
      border-radius: 50%;
      margin-right: 18px;
    }
    .select {
      background: #1874fd !important;
    }
    .disabled {
      border: none;
      background: rgba(112, 112, 112, 0.25);
    }
    .timeSlot {
      font-size: 22px;
      color: rgba(0, 0, 0, 0.85);
      flex: 1;
    }
    .joinNum {
      font-size: 22px;
      color: rgba(0, 0, 0, 0.5);
      text {
        color: #1874fd;
      }
    }
    .isFull {
      color: rgba(0, 0, 0, 0.25) !important;
      text {
        color: rgba(0, 0, 0, 0.25) !important;
      }
    }
  }
}

.seasonal-content {
  padding: 22px 18px 22px;
  box-sizing: border-box;
  .text {
    line-height: 50px;
    text-align: center;
    font-size: 22px;
    font-weight: 600;
    color: #333333;
  }
}
.shortPeriod {
  max-height: 500px;
  overflow-y: auto;
  .scheduleList {
    margin-top: 18px;
    margin-bottom: 37px;
    padding: 19px 0 18px 23px;
    background: #f6f7fa;
    border-radius: 4px;
    border: 1px solid #e4e7ed;
    box-sizing: border-box;
    position: relative;
    .select-img {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 73px;
      height: 64px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .content {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 14px;
      color: #666666;
      line-height: 20px;
      position: relative;
      padding-left: 12px;
      box-sizing: border-box;
      .value {
        span {
          color: #1874fd;
        }
      }
      &:not(:last-child) {
        margin-bottom: 10px;
      }
      &::before {
        content: "";
        position: absolute;
        width: 6px;
        height: 6px;
        background: linear-gradient(180deg, #3397fe 0%, #3666ef 100%);
        border-radius: 50%;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

.section {
  padding: 47px 75px 29px 47px;
  box-sizing: border-box;
  .content {
    display: flex;
    align-items: center;
    width: 100%;

    .label {
      width: 80px;
    }
    .value {
      flex: 1;
      &.sign {
        margin-left: 16px;
      }
      ::v-deep .van-field__body {
        input {
          height: 39px;
          background: #f3f6f8;
          border-radius: 6px;
          padding: 0 15px;
        }
        textarea {
          background: #f3f6f8;
          border-radius: 6px;
          padding: 8px 15px;
        }
      }
    }
  }
}

// .period-dialog {
//   ::v-deep .van-dialog {
//     width: 633px;
//     padding: 37px 38px 48px 36px;
//     box-sizing: border-box;
//     .dialog-header {
//       display: flex;
//       align-items: center;
//       .title {
//         flex: 1;
//         font-weight: 600;
//         font-size: 18px;
//         color: #000000;
//         line-height: 25px;
//         font-style: normal;
//       }
//       img {
//         width: 16px;
//         height: 17px;
//         object-fit: cover;
//       }
//     }
//   }
// }
.operate-r {
  width: 120px;
  height: 50px;
  .operate-rl {
    width: 100%;
    height: 100%;
    font-size: 18px;
    text-align: center;
    font-weight: bold;
    border-radius: 10px;
    overflow: hidden;
  }
  .item,
  .oItem {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    color: #ffffff;
    background: #1874fd;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .activityDetail-red {
    width: 100%;
    height: 100%;
    background: #ed0a10;
  }
  .activityDetail-gray {
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #ededed 0%, #d8d8d8 100%);
    color: #a7a7a7ff;
  }
}

.detailData {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding: 26px 0 32px 0;
  box-sizing: border-box;
  overflow: hidden;
  .setion-content {
    display: flex;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
  .item-l {
    width: 327px;
    flex-shrink: 0;
    margin-right: 60px;
    box-sizing: border-box;
    .active-Picture {
      width: 100%;
      height: 229px;
      border-radius: 4px;
      overflow: hidden;
      box-sizing: border-box;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .rules {
      margin: 13px 0 13px 0;
      box-sizing: border-box;
      font-weight: 500;
      font-size: 20px;
      color: #2f3a4f;
    }
    p {
      display: flex;
      &:not(:last-child) {
        margin-bottom: 15px;
        box-sizing: border-box;
      }
      span {
        font-weight: 400;
        font-size: 18px;
        color: #666666;
        line-height: 25px;
        text-align: left;
        &:first-child {
          width: 126px;
        }
      }
      .number {
        color: #1874fd;
        span {
          color: inherit;
        }
      }
    }
  }
  .item-r {
    flex: 1;
    height: 100%;
    height: calc(100% - 53px);
    overflow-y: auto;
    // padding-bottom: 40px;
    box-sizing: border-box;
    .activityDetail-c {
      .body {
        box-sizing: border-box;
      }
      .title {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 28px;
        color: #2f3a4f;
        line-height: 28px;
        text-align: left;
        font-style: norma;
      }
      .fbImageUrls {
        width: 100%;
        image {
          width: 100%;
          height: 100%;
        }
      }
      .fbVideoUrl {
        width: 100%;
        height: 400px;
        video {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
